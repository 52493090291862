import { Link } from "react-router-dom"

const Users = () => {
    return (
        <div>
            <h1>Users</h1>
            <Link to="/">Home</Link>
            <Link to="/users/1">User 1</Link>
            <Link to="/users/2">User 2</Link>
            <Link to="/users/3">User 3</Link>

        </div>
    );
};

export default Users;